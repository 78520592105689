import assignmentServices from "../../dummy/assignment-services.webp";
import liveSession from "../../dummy/live-session.webp";
import projectReport from "../../dummy/project-report-writing.webp";
import labReport from "../../dummy/lab-report.webp";
import presentationWriting from "../../dummy/presentation-writing.webp";
import homework from "../../dummy/homework.webp";
import exams from "../../dummy/exams.webp";
import quizzes from "../../dummy/quizzes.webp";
import programming from "../../dummy/programming.webp";
import webDevelopment from "../../dummy/web-development.webp";
import appDevelopment from "../../dummy/app-development.webp";
import graphicDesigning from "../../dummy/graphic-designing.webp";
import gameDevelopment from "../../dummy/game-development.webp";
import collegeApplicationEssay from "../../dummy/college-application-essay.webp";
import courseWritingService from "../../dummy/homework.webp";
import caseStudy from "../../dummy/research-proposal.webp";
import annotatedBibliography from "../../dummy/college-application-essay.webp";
import sopWritingService from "../../dummy/presentation-writing.webp";
import dissertations from "../../dummy/web-development.webp";
import researchProposalHelp from "../../dummy/research-proposal.webp";

export const services = [
  // assignment-services
  {
    seoTitle:
      "Expert Assignment Writers | Best Assignment Service Online",
    seoDescription:
      "Secure top-notch assignment help online from proficient writers. Enjoy premium service with punctual delivery, budget-friendly rates, and guaranteed customer satisfaction.",
    altName:"Assignment Help Ireland",
    name: "Assignment Services",
    href: "assignment-services",
    label: "assignment services",
    bannerTitle: "Websites That Help With Assignments",
    bannerDesc:
      "The Assignment Solvers helps you to Revolutionize your career with online homework!",
    aboutTitle:
      // "Assignment Solvers Assignment help: Your Ultimate Destination for Top-Quality Academic Assignments",
      "Paying Someone To Do Assignment",
    aboutImage: assignmentServices,
    serviceAboutSectionPara: [
      "Assignment Solvers offers Pay to Get assignment Done Service. We're your one-stop destination for top-tier Assignment Service, tailored to revolutionize your academic journey. The pursuit of academic excellence doesn't always unfold as expected, presenting challenges that may seem insurmountable. Planning your career path may be secure, but ensuring your academic journey aligns with your aspirations poses a significant challenge.",
      "Assignment Solvers, as a professional academic assistance provider, stands ready to turn these challenges into opportunities for growth and success. Our comprehensive guidance and support in completing academic tasks set us apart in the industry. Whether you find yourself saying, 'I need help with writing an assignment' or require assistance with tight deadlines, our 24/7 commitment ensures you're never alone in challenging situations.",
      "Our dedicated team of writing specialists goes beyond traditional support, offering live sessions to bring you closer to achieving that coveted A+ grade. With a global reputation as the premier academic assistance provider, Assignment Solvers solidifies its position in the EdTech industry through unwavering dedication to your success.",
    ],
    serviceWhySectionPara: [
      "Wondering why you need this particular section? The academic journey, despite careful planning, often deviates from the expected path. Aligning your educational aspirations with the realities you face can be challenging. This is where a professional academic assistance provider becomes invaluable.",
      "Assignment Solvers, as a reliable academic support expert, goes beyond merely improving academic performance. We foster student growth, alleviating the burden of stress that often accompanies academic challenges. Our commitment to comprehensive guidance and support in completing academic tasks sets us apart.",
      "Imagine having access to live sessions that actively contribute to achieving that elusive A+ grade. At Assignment Solvers, our dedicated team of writing specialists ensures you're never alone in facing academic challenges. Whether you're declaring 'I need help with writing an assignment' or navigating tight deadlines, our 24/7 service commitment remains steadfast.",
      "This unwavering dedication has earned Assignment Solvers a global reputation as the premier academic assistance provider, solidifying our position in the EdTech industry. Discover why this section is indispensable on your journey to academic success.",
    ],
    howWorksTitle: "How To Get Help With Assignments",
  },
  // lab-report-help
  {
    seoTitle: "Expert Lab Report Writing Services | Assignment Solvers",
    seoDescription:
      "Looking for assistance with writing a lab report? Trust Assignments Solvers, your reliable lab report writer, ensure to deliver accuracy & quality reports on time. ",
    name: "Lab Report Help",
    href: "lab-report-help",
    label: "lab report help",
    bannerTitle: "lab report help service details",
    bannerDesc:
      "Revolutionize your career with online homework \n help available today!",
    aboutTitle:
      "Assignment Solvers Lab Report Services: Elevate Your Scientific Narrative with Top-Quality Lab Reports",
    aboutImage: labReport,
    serviceAboutSectionPara: [
      "Welcome to Assignment Solvers Lab Reports – your go-to destination for top-notch Lab Report services. Academic pursuits don't always unfold as anticipated, and planning your career path might seem secure, but aligning your academic journey with aspirations poses a significant challenge.",
      "In such circumstances, turning to a professional academic assistance provider proves beneficial. Seeking help not only contributes to improved academic performance but also fosters student growth without the burden of stress. Assignment Solvers, a reliable assistance expert, offers comprehensive guidance and support in completing academic tasks.",
      "As an academic support provider, Assignment Solvers takes an active initiative to provide exceptional Lab Report services. Our dedicated team of specialists ensures that you are never left alone in challenging situations. Whether you declare 'I need help with my Lab Report' or seek assistance with tight deadlines, our 24/7 service commitment remains unwavering.",
      "This dedication has earned us a reputation as the premier academic assistance provider globally, solidifying Assignment Solvers' position in the EdTech industry. Experience excellence in Lab Reports Assignment Solversnt Solvers.",
    ],
    serviceWhySectionPara: [
      "Wondering why you need this particular Lab Report section? Academic pursuits often present unexpected challenges, and ensuring that your academic journey aligns with aspirations can be a significant hurdle. Turning to a professional academic assistance provider, like Assignment Solvers, proves beneficial in such circumstances.",
      "Seeking help from Assignment Solvers goes beyond improving academic performance – it fosters student growth without the burden of stress. Our comprehensive guidance and support in completing academic tasks set us apart as your reliable assistance expert.",
      "Assignment Solvers takes an active initiative to provide Lab Report services, bringing you closer to achieving academic excellence. Our dedicated team of specialists ensures you're never left alone in challenging situations. Whether you declare 'I need help with my Lab Report' or navigate tight deadlines, our 24/7 service commitment remains unwavering.",
      "This dedication has earned us a global reputation as the premier academic assistance provider, solidifying our position in the EdTech industry. Explore why this Lab Report section is indispensable on your journey to academic success with Assignment Solvers Lab Reports.",
    ],
  },
  // project-report-writing
  {
    seoTitle:
      "Premium Assignment Writing Services | Top Project Writers ",
    seoDescription:
      "Get top-notch assignment writing services from expert project writers. Trust our best project writing service for quality work, accuracy and timely delivery.",
    altName:"Report Writing Services Ireland",
    name: "Project Report Writing",
    href: "project-report-writing",
    label: "project report writing",
    bannerTitle: "Project help service details",
    bannerDesc:
      "Revolutionize your career with online homework \n help available today!",
    aboutTitle:
      "Crafting Excellence: Assignment Solvers Ensures Precision and Quality in Project Report Writing Services",
    aboutImage: projectReport,
    serviceAboutSectionPara: [
      "Welcome to Assignment Solvers Project Report Writing – your ultimate destination for top-tier Project Report Writing services. Academic pursuits don't always unfold as anticipated, and planning your career path might seem secure, but aligning your academic journey with aspirations poses a significant challenge.",
      "In such circumstances, turning to a professional academic assistance provider proves beneficial. Seeking help not only contributes to improved academic performance but also fosters student growth without the burden of stress. Assignment Solvers, your reliable assistance expert, offers comprehensive guidance and support in completing academic tasks.",
      "As an academic support provider, Assignment Solvers takes an active initiative to provide exceptional Project Report Writing services. Our dedicated team of specialists ensures that you are never left alone in challenging situations. Whether you declare 'I need help with my Project Report' or seek assistance with tight deadlines, our 24/7 service commitment remains unwavering.",
      "This dedication has earned us a reputation as the premier academic assistance provider globally, solidifying Assignment Solvers' position in the EdTech industry. Experience excellence in Project Report Writing Assignment Solversnt Solvers.",
    ],
    serviceWhySectionPara: [
      "Wondering why you need this particular Project Report Writing section? Academic pursuits often present unexpected challenges, and ensuring that your academic journey aligns with aspirations can be a significant hurdle. Turning to a professional academic assistance provider, like Assignment Solvers, proves beneficial in such circumstances.",
      "Seeking help from Assignment Solvers goes beyond improving academic performance – it fosters student growth without the burden of stress. Our comprehensive guidance and support in completing academic tasks set us apart as your reliable assistance expert.",
      "Assignment Solvers takes an active initiative to provide Project Report Writing services, bringing you closer to achieving academic excellence. Our dedicated team of specialists ensures you're never left alone in challenging situations. Whether you declare 'I need help with my Project Report' or navigate tight deadlines, our 24/7 service commitment remains unwavering.",
      "This dedication has earned us a global reputation as the premier academic assistance provider, solidifying our position in the EdTech industry. Explore why this Project Report Writing section is indispensable on your journey to academic success with Assignment Solvers Project Report Writing.",
    ],
  },
  // homework-help
  {
    // seoTitle: "Expert Homework Help & Assistance for Students | Assignment Solvers",
    seoTitle:
      "Professional Online Homework Help | Top Homework Help Services",
    seoDescription:
      "Struggling with assignments? Access top-notch online homework help services for all subjects. Get assistance from experts to ace your homework effortlessly.",
    name: "Homework Help",
    href: "homework-help",
    label: "homework help",
    bannerTitle: "BEST WEBSITES TO HELP WITH HOMEWORK",
    bannerDesc:
      "Do you have lot's of Homework? Don't worry, Our expert are ready to help you right now.",
    aboutTitle: "Website To Pay Someone To Do My Homework",
    aboutImage: homework,
    serviceAboutSectionPara: [
      "The Assignment Solvers Provide Pay Only For Homework Service. You can take the help of our professionals to do your homework.Assignment Solversnt Solvers Provide Pay Only For Homework Service. You can take the help of our professionals to do your homeAssignment Solversignment Solvers Provide Pay Only For Homework Service. You can take the help of our professionals to do yourAssignment Solverse Assignment Solvers Provide Pay Only For Homework Service. You can take the help of our professionals to doAssignment Solversk. The Assignment Solvers Provide Pay Only For Homework Service. You can take the help of our professionals Assignment Solversmework. The Assignment Solvers Provide Pay Only For Homework Service. You can take the help of our professioAssignment Solversur homework. The Assignment Solvers Provide Pay Only For Homework Service. You can take the help of our profAssignment Solversdo your homework. The Assignment Solvers Provide Pay Only For Homework Service. You can take the help of our professionals to Assignment Solversork. Discover the world of Assignment Solvers Homework – your go-to destination for unparalleled Homework assistance. Academic pursuits don't always unfold as anticipated, and planning your career path might seem secure, but aligning your academic journey with aspirations poses a significant challenge.",
      "In such circumstances, turning to a professional academic assistance provider proves beneficial. Seeking help not only contributes to improved academic performance but also fosters student growth without the burden of stress. Assignment Solvers stands out as one reliable assistance expert, offering comprehensive guidance and support in completing academic tasks.",
      "As an academic support provider, Assignment Solvers takes an active initiative to provide Homework assistance, bringing students closer to achieving an A+ grade. Our dedicated team of writing specialists ensures that you are never left alone in challenging situations. Whether you declare 'I need help with my Homework' or seek assistance with tight deadlines, our 24/7 service commitment remains unwavering.",
      "This dedication has earned us a reputation as the premier academic assistance provider globally, solidifying Assignment Solvers' position in the EdTech industry. Experience excellence in Homework assistance Assignment Solversnt Solvers.",
    ],
    whyTitle: "What Website can Help Me with My Homework",
    serviceWhySectionPara: [
      "Many websites offers Homework service, But The Assignment Solvers Offers the most affordable & reliable Homework Service. Wondering why you need this particular Homework section? Academic pursuits often present unexpected challenges, and ensuring that your academic journey aligns with your aspirations can be a significant hurdle. Turning to a professional academic assistance provider, Assignment Solversnt Solvers, proves beneficial in such circumstances.",
      "Seeking help from Assignment Solvers goes beyond improving academic performance – it fosters student growth without the burden of stress. Our comprehensive guidance and support in completing academic tasks set us apart as your reliable assistance expert.",
      "Assignment Solvers takes an active initiative to provide Homework assistance, bringing you closer to achieving academic excellence. Our dedicated team of specialists ensures you're never left alone in challenging situations. Whether you declare 'I need help with my Homework' or navigate tight deadlines, our 24/7 service commitment remains unwavering.",
      "This dedication has earned us a global reputation as the premier academic assistance provider, solidifying our position in the EdTech industry. Explore why this Homework section is indispensable on your journey to academic success with Assignment Solvers Homework.",
    ],
    howWorksTitle: "How To Hire Someone To Do Your Homework",
  },
  // exam-help
  {
    seoTitle:
      "Online Exam Help | Best Assistance for Exams | Assignments Solvers",
    seoDescription:
      "The best online exam help from experts. Trust assignments solvers reliable service for assistance with exams. Achieve success with our professional support.",
    name: "Exam Help",
    href: "exam-help",
    label: "exam help",
    bannerTitle: "Exam help service details",
    bannerDesc:
      "Revolutionize your career with online homework \n help available today!",
    aboutTitle:
      "Empower Your Success: Assignment Solvers' Comprehensive Exam Support Services for Academic Excellence",
    aboutImage: exams,
    serviceAboutSectionPara: [
      "Welcome to Assignment Solvers Exams – your ultimate destination for top-notch Exam assistance. Academic pursuits don't always unfold as anticipated, and while planning your career path may seem secure, aligning your academic journey with aspirations poses a significant challenge.",
      "In such circumstances, turning to a professional academic assistance provider proves beneficial. Seeking help from Assignment Solvers not only contributes to improved academic performance but also fosters student growth without the burden of strAssignment Solversnt Solvers stands out as a reliable assistance expert, offering comprehensive guidance and support in completing academic tasks.",
      "As an academic support provider, Assignment Solvers takes an active initiative to provide Exam assistance, bringing students closer to achieving an A+ grade. Our dedicated team of writing specialists ensures that you are never left alone in challenging situations. Whether you declare 'I need help with my Exams' or seek assistance with tight deadlines, our 24/7 service commitment remains unwavering.",
      "This dedication has earned us a reputation as the premier academic assistance provider globally, solidifying Assignment Solvers' position in the EdTech industry. Experience excellence in Exam assistance Assignment Solversnt Solvers.",
    ],
    serviceWhySectionPara: [
      "Curious about why you need this particular Exams section? Academic pursuits often present unexpected challenges, and ensuring that your academic journey aligns with aspirations can be a significant hurdle. Turning to a professional academic assistance provider, like Assignment Solvers, proves beneficial in such circumstances.",
      "Seeking help from Assignment Solvers goes beyond improving academic performance – it fosters student growth without the burden of stress. Our comprehensive guidance and support in completing academic tasks set us apart as your reliable assistance expert.",
      "Assignment Solvers takes an active initiative to provide Exam assistance, bringing you closer to achieving academic excellence. Our dedicated team of specialists ensures you're never left alone in challenging situations. Whether you declare 'I need help with my Exams' or navigate tight deadlines, our 24/7 service commitment remains unwavering.",
      "This dedication has earned us a global reputation as the premier academic assistance provider, solidifying our position in the EdTech industry. Explore why this Exams section is indispensable on your journey to academic success with Assignment Solvers Exams.",
    ],
  },
  // quizzes-help
  {
    seoTitle:
      "Premier Online Quiz Help Service for Academic | Assignment Solvers",
    seoDescription:
      "Elevate your performance with Assignments Solvers, the leading online quiz help service. Trust our expertise for unmatched assistance. Achieve success with ease!",
    name: "Quizzes Help",
    href: "quizzes-help",
    label: "quizzes help",
    bannerTitle: "Quizzes help service details",
    bannerDesc:
      "Revolutionize your career with online homework \n help available today!",
    aboutTitle:
      "Quiz Mastery Unleashed: Elevate Your Learning with Assignment Solvers' Dynamic Quiz Support Services",
    aboutImage: quizzes,
    serviceAboutSectionPara: [
      "Welcome to Assignment Solvers Quizzes – your ultimate destination for top-quality Quiz assistance. Do academic pursuits always unfold as anticipated? More often than not, the answer is a resounding No. While planning your career path may seem secure, ensuring that your academic journey aligns with those aspirations poses a significant challenge.",
      "In such circumstances, turning to a professional academic assistance provider proves beneficial. Seeking help from Assignment Solvers not only contributes to improved academic performance but also fosters student growth without the burden of strAssignment Solversnt Solvers stands out as a reliable assistance expert, offering comprehensive guidance and support in completing academic tasks.",
      "As an academic support provider, Assignment Solvers takes an active initiative to provide Quiz assistance, bringing students closer to achieving an A+ grade. Our dedicated team of writing specialists ensures that you are never left alone in challenging situations. Whether you declare 'I need help with my Quizzes' or seek assistance with tight deadlines, our 24/7 service commitment remains unwavering.",
      "This dedication has earned us a reputation as the premier academic assistance provider globally, solidifying Assignment Solvers' position in the EdTech industry. Elevate your Quiz experience with excellence, onlAssignment Solversnt Solvers Quizzes.",
    ],
    serviceWhySectionPara: [
      "Curious about why you need this particular Quizzes section? Academic pursuits often present unexpected challenges, and ensuring that your academic journey aligns with aspirations can be a significant hurdle. Turning to a professional academic assistance provider, like Assignment Solvers, proves beneficial in such circumstances.",
      "Seeking help from Assignment Solvers goes beyond improving academic performance – it fosters student growth without the burden of stress. Our comprehensive guidance and support in completing academic tasks set us apart as your reliable assistance expert.",
      "Assignment Solvers takes an active initiative to provide Quiz assistance, bringing you closer to achieving academic excellence. Our dedicated team of specialists ensures you're never left alone in challenging situations. Whether you declare 'I need help with my Quizzes' or navigate tight deadlines, our 24/7 service commitment remains unwavering.",
      "This dedication has earned us a global reputation as the premier academic assistance provider, solidifying our position in the EdTech industry. Discover the compelling reasons behind the indispensability of the Quizzes section for your academic success with Assignment Solvers Quizzes.",
    ],
  },
  // dissertations
  {
    seoTitle:
      "Dissertation Help & Writing Assistance | Assignments Solvers",
    seoDescription:
      "Looking for reliable dissertation help online? Assignments Solvers delivers top-notch dissertation writing services, ensuring academic success.",
      altName:"Dissertation Help Services",
    name: "Dissertations",
    href: "dissertations",
    label: "dissertations",
    bannerTitle: "CHEAP DISSERTATION ONLINE SERVICES",
    bannerDesc:
      "The Assignment Solvers Offers Low Price Online Dissertation Writing Services with additional Freebies",
    aboutTitle: "Dissertation Ghost Writers",
    aboutImage: dissertations,
    serviceAboutSectionPara: [
      "Our Dissertation Ghost Writers are highly skilled into writing dissertation in any person's voice. Embark on an academic journey of excellence with Assignment Solvers Dissertations – your go-to destination for unparalleled quality in dissertation assistance. Do academic pursuits always unfold as anticipated? More often than not, the answer is a resounding No. While planning your career path may seem secure, ensuring that your academic journey aligns with those aspirations poses a significant challenge.",
      "In such circumstances, turning to Assignment Solvers, a professional academic assistance provider, proves beneficial. Seeking help from us not only contributes to improved academic performance but also fosters student growth without the burden of stressAssignment Solversnt Solvers, we stand as a reliable assistance expert, offering comprehensive guidance and support in completing academic tasks.",
      "As an academic support provider, Assignment Solvers takes an active initiative to provide dissertation assistance, bringing students closer to achieving an A+ grade. Our dedicated team of writing specialists ensures that you are never left alone in challenging situations. Whether you declare 'I need help with my dissertation' or seek assistance with tight deadlines, our 24/7 service commitment remains unwavering.",
      "This dedication has earned us a reputation as the premier academic assistance provider globally, solidifying Assignment Solvers' position in the EdTech industry. Join uAssignment Solversnt Solvers Dissertations and revolutionize your academic path.",
    ],
    whyTitle: "Is Doing a Dissertation Worth it ?",
    serviceWhySectionPara: [
      "Yes, Here is how, Academic pursuits often present unexpected challenges, and ensuring that your academic journey aligns with aspirations can be a significant hurdle. Turning to a professional academic assistance provider, like Assignment Solvers, proves beneficial in such circumstances.",
      "Seeking help from Assignment Solvers goes beyond improving academic performance – it fosters student growth without the burden of stress. We stand as your reliable assistance expert, offering comprehensive guidance and support in completing academic tasks.",
      "Assignment Solvers takes an active initiative to provide dissertation assistance, bringing you closer to achieving academic excellence. Our dedicated team of specialists ensures you're never left alone in challenging situations. Whether you declare 'I need help with my dissertation' or navigate tight deadlines, our 24/7 service commitment remains unwavering.",
      "This dedication has earned us a global reputation as the premier academic assistance provider, solidifying our position in the EdTech industry. Discover the compelling reasons behind the indispensability of the Dissertations section for your academic success with Assignment Solvers Dissertations.",
    ],
  },
  // term-paper-help
  {
    seoTitle: "Expert Term Paper Writer & Help Assistance | Assignments Solvers",
    seoDescription:
      "Seeking term paper help? Discover expert term paper writers at Assignments Solvers. Get reliable assistance for your academic success. Let us help you today! ",
    name: "Term Paper Help",
    href: "term-paper-help",
    label: "term paper help",
    bannerTitle: "term paper help service details",
    bannerDesc:
      "Revolutionize your career with online homework \n help available today!",
    aboutTitle:
      "Unlock Your Academic Future: Assignment Solvers Craft Personalized College Application Essays for Your Journey to Success",
    aboutImage: collegeApplicationEssay,
    serviceAboutSectionPara: [
      "Embark on a journey where academic excellence meets personalized guidance. At Assignment Solvers, we understand that the path of academic pursuits is rarely a straight line. Despite meticulous planning, the challenges of aligning your academic journey with your aspirations often arise.",
      "This is where Assignment Solvers steps in as your academic ally. We offer more than just assistance; we provide a transformative experience. Our dedicated team of writing specialists ensures that you receive comprehensive support to navigate through academic tasks, bringing you closer to that coveted A+ grade.",
      "Our commitment goes beyond conventional tutoring. We offer live sessions, ensuring that you're never alone in challenging situations. Whether you're declaring 'I need help with writing an assignment' or seeking support with tight deadlines, our 24/7 service remains unwavering.",
      "Assignment Solvers has earned a global reputation as the premier academic assistance provider, solidifying our position in the EdTech industry. Join us in revolutionizing your academic journey and unlocking your true potential.",
    ],
    serviceWhySectionPara: [
      "Choosing the right academic assistance provider is crucial. At Assignment Solvers, we stand out for several compelling reasons. Our commitment to your success goes beyond traditional tutoring services. Here's why Assignment Solvers's College Application Essay Service is your best choice:",
      "1. **Comprehensive Guidance:** We don't just assist; we guide you through a transformative academic experience, ensuring your journey is as enriching as the destination.",
      "2. **Dedicated Team:** Our team of writing specialists is committed to your success. With Assignment Solvers, you're not just getting support; you're gaining a team of allies.",
      "3. **Live Sessions:** Connect with us in real-time. Our live sessions bring the classroom experience to your doorstep, providing immediate solutions to your academic challenges.",
      "4. **24/7 Support:** Academic challenges don't wait for office hours. Neither do we. Whenever you declare 'I need help with writing an assignment,' we're here, day or night.",
      "Choose Assignment Solvers for an unparalleled academic journey. Your success is our priority, and we're here to redefine what's possible in your academic career.",
    ],
  },
  // research-paper-help
  {
    seoTitle:
      "Get Best Research Paper Writing Services by Professionals",
    seoDescription:
      "Explore the best research paper writing services at Assignments Solvers. Our expert writers deliver high-quality papers to meet your academic needs. Contact us now!",
    name: "Research Paper Help",
    href: "research-paper-help",
    label: "research paper help",
    bannerTitle: "Research paper help service details",
    bannerDesc:
      "Revolutionize your career with online homework \n help available today!",
    aboutTitle:
      "Navigating Research Excellence: Assignment Solvers' Expert Guidance in Crafting Precision with Research Proposal Help",
    aboutImage: researchProposalHelp,
    serviceAboutSectionPara: [
      "Embark on a journey of academic excellence with Assignment Solvers, where we understand that academic pursuits don't always unfold as anticipated. Planning your career path may seem secure, but aligning your academic journey with aspirations poses a significant challenge.",
      "In these circumstances, seeking help from a professional academic assistance provider is not just beneficial; it's essential. Assignment Solvers stands as your reliable partner, contributing to improved academic performance and fostering your growth as a student without the burden of stress.",
      "As academic support providers, we take an active initiative to provide live sessions that bring you closer to achieving that coveted A+ grade. Our dedicated team of writing specialists ensures you're never left alone in challenging situations. Whether you declare 'I need help with writing an assignment' or require assistance with tight deadlines, our 24/7 service commitment remains unwavering.",
      "This unwavering dedication has earned us a reputation as the premier academic assistance provider globally, solidifying Assignment Solvers' position in the EdTech industry.",
    ],
    serviceWhySectionPara: [
      "Why should you choose Assignment Solvers for your research proposal help? Let's uncover the reasons behind the excellence that sets us apart:",
      "1. **Expert Guidance:** Our assistance goes beyond routine help. We provide expert guidance, ensuring your research proposal stands out and aligns perfectly with your academic goals.",
      "2. **Comprehensive Support:** Assignment Solvers takes an active initiative to provide comprehensive guidance and support in completing academic tasks. We don't just help; we empower you to excel.",
      "3. **Live Sessions for Success:** Experience a dynamic learning environment. Our live sessions, designed to bring you closer to achieving an A+ grade, are a testament to our commitment to your success.",
      "4. **24/7 Dedicated Support:** Academic challenges don't wait for a convenient time. Neither do we. Whether it's 'I need help with writing an assignment' or tight deadlines, we're here for you, 24/7.",
      "Choose Assignment Solvers for your research proposal needs and witness the transformation of your academic journey. Your success is our priority.",
    ],
  },
  // case-study-help
  {
    seoTitle:
      "Case Study Writing Service by Professionals | Assignments Solvers",
    seoDescription:
      "Need a professional case study writer? Assignments Solvers provides top-notch high-quality case study writing service. Let our experts handle your case study needs.",
    name: "Case Study Help",
    href: "case-study-help",
    label: "case study help",
    bannerTitle: "case study help service details",
    bannerDesc:
      "Revolutionize your career with online homework \n help available today!",
    aboutTitle:
      "Case Study Excellence Unveiled: Assignment Solvers' Tailored Solutions in Crafting Your Narrative with Precision",
    aboutImage: caseStudy,
    serviceAboutSectionPara: [
      "At Assignment Solvers, we recognize that academic pursuits don't always unfold as anticipated. Planning your career path might seem secure, but aligning your academic journey with aspirations poses a significant challenge.",
      "In such circumstances, seeking professional academic assistance becomes a game-changer. It not only contributes to improved academic performance but also fosters student growth without the burden of stress. Enter Assignment Solvers – your reliable partner offering comprehensive guidance and support to navigate the complexities of academic tasks.",
      "As academic support providers, we take an active initiative to provide live sessions, bringing you closer to achieving that coveted A+ grade. Our dedicated team of writing specialists ensures you're never alone in challenging situations. Whether you declare 'I need help with writing an assignment' or require assistance with tight deadlines, our unwavering 24/7 service commitment remains your academic safety net.",
      "This unwavering dedication has earned us a reputation as the premier academic assistance provider globally, solidifying Assignment Solvers' position in the EdTech industry.",
    ],
    serviceWhySectionPara: [
      "Why should you choose Assignment Solvers for your 'Write My Case Study' needs? Let's delve into the reasons that make us stand out:",
      "1. **Expert Assistance:** Our service goes beyond basic help. We provide expert assistance, ensuring your case study meets the highest academic standards and showcases your understanding of the subject.",
      "2. **Comprehensive Guidance:** Assignment Solvers takes an active initiative to provide comprehensive guidance and support in completing academic tasks. We don't just solve problems; we empower you to excel.",
      "3. **Live Sessions for Excellence:** Experience a dynamic learning environment. Our live sessions are designed to bring you closer to achieving an A+ grade, a testament to our commitment to your success.",
      "4. **Round-the-Clock Support:** Academic challenges can arise at any time. Whether it's 'I need help with writing an assignment' or dealing with tight deadlines, our 24/7 dedicated support ensures you're covered.",
      "Choose Assignment Solvers for your 'Write My Case Study' requirements, and witness a transformation in your academic journey. Your success is not just a goal; it's our commitment.",
    ],
  },
  // thesis-help
  {
    seoTitle:
      "Thesis Writing Help & PhD Thesis Services | Assignments Solvers",
    seoDescription:
      "Seeking expert thesis writing help or PhD thesis services? Turn to Assignments Solvers for top high-quality assistance in crafting your academic masterpiece.  ",
    name: "Thesis Help",
    href: "thesis-help",
    label: "thesis help",
    bannerTitle: "Thesis help service details",
    bannerDesc:
      "Revolutionize your career with online homework \n help available today!",
    aboutTitle:
      "Annotated Brilliance Unleashed: Assignment Solvers' Expertise in Crafting Your Comprehensive Bibliography with Precision",
    aboutImage: annotatedBibliography,
    serviceAboutSectionPara: [
      "Embark on a journey where academic pursuits unfold with certainty. At Assignment Solvers, we understand that planning your career path may seem secure, but aligning your academic journey with aspirations poses a significant challenge.",
      "In these circumstances, seeking the support of a professional academic assistance provider proves invaluable. Our assistance not only contributes to improved academic performance but also fosters your personal growth without the burden of stress. Assignment Solvers stands out as a reliable guide, offering comprehensive assistance in completing academic tasks.",
      "As dedicated academic support providers, we take an active initiative to provide live sessions that bring you closer to achieving that coveted A+ grade. Our team of writing specialists ensures you're never left alone in challenging situations. Whether you declare 'I need help with writing an assignment' or require assistance with tight deadlines, our 24/7 service commitment remains unwavering.",
      "This unwavering dedication has earned us a reputation as the premier academic assistance provider globally, solidifying Assignment Solvers' position in the EdTech industry.",
    ],
    serviceWhySectionPara: [
      "Why should you choose Assignment Solvers for your 'Write My Annotated Bibliography' needs? Let's delve into the compelling reasons that make us your ideal choice:",
      "1. **Expert Annotated Bibliography Writing:** Assignment Solvers goes beyond basic assistance. We provide expertly crafted annotated bibliographies that meet the highest academic standards, showcasing your understanding of the subject.",
      "2. **Comprehensive Guidance:** Our active initiative to provide comprehensive guidance and support sets us apart. We don't just solve problems; we empower you to excel in your academic endeavors.",
      "3. **Live Sessions for Academic Excellence:** Experience dynamic learning through our live sessions, carefully designed to bring you closer to achieving an A+ grade. It's a testament to our commitment to your success.",
      "4. **24/7 Dedicated Support:** Academic challenges don't adhere to schedules. Whether it's a declaration of 'I need help with writing an assignment' or dealing with tight deadlines, our round-the-clock support ensures you're covered at all times.",
      "Choose Assignment Solvers for your 'Write My Annotated Bibliography' requirements, and witness a transformation in your academic journey. Your success is not just a goal; it's our unwavering commitment.",
    ],
  },
];
// Assignment services
// Homework help
// Lab Report help
// Exam help
// Quizzes help
// Dissertations
// Project report writing
// research paper help
// thesis help
// term paper help
// case study help

export const faq = [
  // General FAQ
  {
    id: 1,
    title: "Why Choose Assignment Solvers's tutoring services?",
    content: [
      "- Tutoring on more than 100+ subjects",
      "- PhD Experts with 10+ years of experience offering tutoring Help",
      "- Lowest possible prices for tutoring",
      "- Amazing 24x7 live support — highly qualified, experienced and friendly customer service executives",
      "- Experience our services to believe in us",
    ],
  },
  {
    id: 2,
    title: "For what levels, do you provide tutoring?",
    content: [
      "We provide tutoring and guidance for all levels from primary, high school, grad and post grad levels.",
    ],
  },
  {
    id: 3,
    title: "What are the areas and domains in which you provide tutoring?",
    content: [
      "We provide expert guidance on Management, Law , Literature, English, Science, Maths etc.",
    ],
  },
  {
    id: 4,
    title: "How is your tutoring service different from others?",
    content: [
      "Our PhD tutors have been drawn from reputed institutes having 10+ years of teaching experience in various institutes. They have been trained on best learning methodologies. We have PhDs drawn from various domains and specialisations. We have an inhouse benchmark where only those tutors are selected whose tutoring enabled student to get A grades. Not only that, top 5% of the academic writers guide you during the writing process.",
    ],
  },
  {
    id: 5,
    title: "How do I trust you with any payment?",
    content: [
      "You are given an order confirmation number as soon as you make the payment. We ensure that the most credible and safe gateway is used for payment with firewall and multiple security options. 24* 7 live support is provided for all payment details.",
    ],
  },
  {
    id: 6,
    title: "How do I know that I would get quality tutors?",
    content: ["You can have live interaction with our tutors along with"],
  },
  {
    id: 7,
    title: "Can I talk with your tutor?",
    content: [
      "Yes, you can have live interaction with out tutors anytime you face a problem.",
    ],
  },
  {
    id: 8,
    title: "Are my personal details provided to my tutor?",
    content: [
      "No, your personal details are neither divulged to any party nor provided to your tutors",
    ],
  },
  // services FAQ
  {
    label: "services",
    id: 9,
    title: "Why Choose Assignment Solvers's writing services?",
    content: [
      // services related content here
    ],
  },
  {
    label: "services",
    id: 10,
    title: "For what levels, do you provide writing assistance?",
    content: [
      // services related content here
    ],
  },
  {
    label: "services",
    id: 11,
    title: "What are the areas and domains in which you provide academic help?",
    content: [
      // services related content here
    ],
  },
  {
    label: "services",
    id: 12,
    title: "How is your writing service different from others?",
    content: [
      // services related content here
    ],
  },
  {
    label: "services",
    id: 13,
    title: "How do I trust you with any payment?",
    content: [
      // services related content here
    ],
  },
  {
    label: "services",
    id: 14,
    title: "How do I know that I would get quality work?",
    content: [
      // services related content here
    ],
  },
  {
    label: "services",
    id: 15,
    title: "Can I talk with your tutor?",
    content: [
      // services related content here
    ],
  },
  {
    label: "services",
    id: 16,
    title: "Are my personal details provided to my helper?",
    content: [
      // services related content here
    ],
  },
  // Assignment Help FAQ
  {
    label: "assignment-services",
    id: 17,
    title: "How does your assignment writing service work?",
    content: [
      // Add assignment service related content here
      "The Assignment Solvers assignment writing process involves clients providing task details, receiving a cost estimate, making a 50% advance payment for assignment initiation, getting timely notifications on completion, settling the remaining payment for the finished assignment, and accommodating free revisions with potential additional costs for new changes. This organized and transparent approach emphasizes clear communication and sets expectationsAssignment Solversnt Solvers' clients.",
    ],
  },
  {
    label: "assignment-services",
    id: 18,
    title:
      "What are the key benefits of using a professional assignment writing service?",
    content: [
      // Add assignment service related content here
      "Engaging with Assignment Solvers assures expertly crafted, plagiarism-free assignments tailored to client needs, reducing stress and providing learning opportunities, all while maintaining strict confidentiality and offering convenient, 24/7 support.",
    ],
  },
  {
    label: "assignment-services",
    id: 19,
    title:
      "How much does it cost to get an assignment written by your service?",
    content: [
      // Add assignment service related content here
      "For pricing details on our assignment writing service, reach out via WhatsApp or email, where we assess costs based on complexity, tutor charges, and deadlines, ensuring a customized quote aligned with your specific requirements.",
    ],
  },
  {
    label: "assignment-services",
    id: 20,
    title: "How quickly can I get my assignment done if it's urgent?",
    content: [
      // Add assignment service related content here
      "The speed of completing your assignment depends on factors like task complexity, expert availability, and your provided deadline. It's crucial for the deadline to be realistic; extremely tight turnarounds may be impractical, but if the project requires only 3-4 hours of work, it can be accommodated accordingly.",
    ],
  },
  {
    label: "assignment-services",
    id: 21,
    title: "Are your services available for international students as well?",
    content: [
      // Add assignment service related content here
      "Absolutely, our services are available for students, professionals, and clients worldwide. We cater to individuals from all corners of the globe.",
    ],
  },
  {
    label: "assignment-services",
    id: 22,
    title: "What subjects do you cover in your assignment writing services?",
    content: [
      // Add assignment service related content here
      "Certainly, we offer assistance across all subjects, covering diverse fields and courses, including support for company projects.",
    ],
  },
  {
    label: "assignment-services",
    id: 23,
    title: "Why Choose us for assignment writing services?",
    content: [
      // Add assignment service related content here
      "Opt for Assignment Solvers for assignment writing services, featuring 800+ tutors, 24/7 support, timely delivery, affordable pricing, plagiarism-free content, and secured refunds via Paypal and Razorpay. Enjoy benefits like unlimited revisions, AI-free assignments, confidentiality, assistance from multiple experts, rewards, lifetime access, free SMS updates, and on-demand phone calls with our relationship manager for instant query resolution.",
    ],
  },
  // essay writing Services FAQ
  {
    label: "essay-writing-services",
    id: 24,
    title: "",
    content: [
      // Add essay writing service related content here
    ],
  },
  {
    label: "essay-writing-services",
    id: 25,
    title: "Are your essays plagiarism-free?",
    content: [
      // Add essay writing service related content here
    ],
  },
  {
    label: "essay-writing-services",
    id: 26,
    title: "What are the benefits of using essay writing services?",
    content: [
      // Add essay writing service related content here
    ],
  },
  {
    label: "essay-writing-services",
    id: 27,
    title: "Is it OK to use an essay writing service?",
    content: [
      // Add essay writing service related content here
    ],
  },
  {
    label: "essay-writing-services",
    id: 28,
    title: "How much do essay writing services cost?",
    content: [
      // Add essay writing service related content here
    ],
  },
  {
    label: "essay-writing-services",
    id: 29,
    title: "Are revisions included in the service, and how many can I request?",
    content: [
      // Add essay writing service related content here
    ],
  },
  {
    label: "essay-writing-services",
    id: 30,
    title: "Do you guarantee the quality of your work?",
    content: [
      // Add essay writing service related content here
    ],
  },
  {
    label: "essay-writing-services",
    id: 31,
    title: "Why choose us for essay writing services ?",
    content: [
      // Add essay writing service related content here
    ],
  },
  // project-report-writing FAQ
  {
    label: "project-report-writing",
    id: 32,
    title: "What services do you offer for project report writing?",
    content: [
      // Add project-report-writing service related content here
    ],
  },
  {
    label: "project-report-writing",
    id: 33,
    title: "How do you ensure the quality of the project reports?",
    content: [
      // Add project-report-writing service related content here
    ],
  },
  {
    label: "project-report-writing",
    id: 34,
    title: "Is my information kept confidential?",
    content: [
      // Add project-report-writing service related content here
    ],
  },
  {
    label: "project-report-writing",
    id: 35,
    title: "How much does project report writing service cost?",
    content: [
      // Add project-report-writing service related content here
    ],
  },
  {
    label: "project-report-writing",
    id: 36,
    title:
      "Can I request specific sections to be included in the project report?",
    content: [
      // Add project-report-writing service related content here
    ],
  },
  {
    label: "project-report-writing",
    id: 37,
    title: "Do you provide custom formatting for project reports?",
    content: [
      // Add project-report-writing service related content here
    ],
  },
  {
    label: "project-report-writing",
    id: 38,
    title: "How long does it take to write a project report?",
    content: [
      // Add project-report-writing service related content here
    ],
  },
  {
    label: "project-report-writing",
    id: 39,
    title: "What information do I need to provide you?",
    content: [
      // Add project-report-writing service related content here
    ],
  },
  {
    label: "project-report-writing",
    id: 40,
    title: "Why choose us for project report writing services?",
    content: [
      // Add project-report-writing service related content here
    ],
  },
  // speech-writing FAQ
  {
    label: "speech-writing",
    id: 41,
    title: "Can you help with speeches for different occasions?",
    content: [
      // Add speech-writing service related content here
    ],
  },
  {
    label: "speech-writing",
    id: 42,
    title: "How much does your speech writing service cost?",
    content: [
      // Add speech-writing service related content here
    ],
  },
  {
    label: "speech-writing",
    id: 43,
    title: "Can you provide last-minute speech writing services?",
    content: [
      // Add speech-writing service related content here
    ],
  },
  {
    label: "speech-writing",
    id: 44,
    title: "Are there sample speeches I can review before hiring your service?",
    content: [
      // Add speech-writing service related content here
    ],
  },
  {
    label: "speech-writing",
    id: 45,
    title: "How can I provide input on the speech writing process?",
    content: [
      // Add speech-writing service related content here
    ],
  },
  {
    label: "speech-writing",
    id: 46,
    title: "What information do I need to provide you to get started?",
    content: [
      // Add speech-writing service related content here
    ],
  },
  {
    label: "speech-writing",
    id: 47,
    title: "Why Choose us for speech writing?",
    content: [
      // Add speech-writing service related content here
    ],
  },
  // presentation-writing FAQ
  {
    label: "presentation-writing",
    id: 48,
    title: "What services do you offer for presentation writing?",
    content: [
      // Add presentation writing service related content here
      "We offer custom-made PowerPoint presentations crafted by experts to meet your specific needs and requirements.",
    ],
  },
  {
    label: "presentation-writing",
    id: 49,
    title: "Can you handle urgent requests for presentation writing?",
    content: [
      // Add presentation writing service related content here
      "Yes, we can handle urgent requests for presentation writing.",
    ],
  },
  {
    label: "presentation-writing",
    id: 50,
    title: "Are my details and information kept confidential?",
    content: [
      // Add presentation writing service related content here
      "Yes, your details and information are kept confidential.",
    ],
  },
  {
    label: "presentation-writing",
    id: 51,
    title: "How much does presentation writing cost?",
    content: [
      // Add presentation writing service related content here
      "The cost of presentation writing varies depending on factors such as the complexity of the project, the length of the presentation, and any additional requirements. We offer customized pricing based on your specific needs.",
    ],
  },
  {
    label: "presentation-writing",
    id: 52,
    title: "How long does it take to complete a presentation?",
    content: [
      // Add presentation writing service related content here
      "The time it takes to complete a presentation depends on factors such as its length, complexity, and any specific requirements. We strive to deliver presentations in a timely manner while ensuring high quality.",
    ],
  },
  {
    label: "presentation-writing",
    id: 53,
    title: "What revisions are included in presentation writing services?",
    content: [
      // Add presentation writing service related content here
      "Revisions included in our presentation writing services are based on the initial requirements provided. Minor revisions are typically done free of charge, ensuring the content aligns with your specifications. However, significant changes may incur additional charges, and we'll discuss this with you before proceeding.",
    ],
  },
  {
    label: "presentation-writing",
    id: 54,
    title: "Why choose us for presentation writing services?",
    content: [
      // Add presentation writing service related content here
      "Choose us for presentation writing services because we craft PowerPoint presentations tailored to your instructions, ensuring timely delivery without relying on AI tools.",
    ],
  },
  // homework FAQ
  {
    label: "homework-help",
    id: 55,
    title: "How does your homework help service work?",
    content: [
      // Add homework help service related content here
    ],
  },
  {
    label: "homework-help",
    id: 56,
    title: "What services do you offer for homework help?",
    content: [
      // Add homework help service related content here
    ],
  },
  {
    label: "homework-help",
    id: 57,
    title: "Is the homework help service confidential?",
    content: [
      // Add homework help service related content here
    ],
  },
  {
    label: "homework-help",
    id: 58,
    title: "How quickly can I get my homework done using your service?",
    content: [
      // Add homework help service related content here
    ],
  },
  {
    label: "homework-help",
    id: 59,
    title:
      "Do you provide support for students at different academic levels (high school, college, etc.)?",
    content: [
      // Add homework help service related content here
    ],
  },
  {
    label: "homework-help",
    id: 60,
    title: "How much does your homework help service cost?",
    content: [
      // Add homework help service related content here
    ],
  },
  {
    label: "homework-help",
    id: 61,
    title: "Why choose us for homework help?",
    content: [
      // Add homework help service related content here
    ],
  },
  // {
  //   label: "homework-help",
  //   id: 144,
  //   title: "Coding homework help free?",
  //   content: [
  //     // Add homework help service related content here
  //     "You can go to this Website https://assignmentssolvers.com/online-assignment-help/services/programming",
  //   ],
  // },
  // dissertations FAQ
  {
    label: "dissertations",
    id: 62,
    title: "What services do you offer for dissertation writing?",
    content: [
      // Add dissertations service related content here
      "We assist students in writing plagiarism-free dissertations to help them succeed in their academic endeavors.",
    ],
  },
  {
    label: "dissertations",
    id: 63,
    title: "Can I communicate with the writer working on my dissertation?",
    content: [
      // Add dissertations service related content here
      "Certainly! You can communicate with the writer working on your dissertation through us. We act as an intermediary, facilitating direct communication between you and the expert writer via virtual meetings to ensure efficient collaboration and the best outcome for your project.",
    ],
  },
  {
    label: "dissertations",
    id: 64,
    title:
      "What guarantees do you offer regarding the quality of the dissertation?",
    content: [
      // Add dissertations service related content here
      "Absolutely! We provide a free Turnitin report to ensure the uniqueness and high quality of the dissertation, aiming to secure the best grades for you.",
    ],
  },
  {
    label: "dissertations",
    id: 65,
    title: "How long does it take to complete a dissertation?",
    content: [
      // Add dissertations service related content here
      "We strive to complete dissertations within the given deadline, ensuring timely delivery whenever humanly pos",
    ],
  },
  {
    label: "dissertations",
    id: 66,
    title: "Can I request revisions after receiving the final dissertation?",
    content: [
      // Add dissertations service related content here
      "Certainly, revisions that involve major changes or deviate from the initial instructions may be subject to additional charges. We strive to ensure that the final dissertation meets your expectations based on the original requirements provided.",
    ],
  },
  {
    label: "dissertations",
    id: 67,
    title:
      "Are your dissertation writing services available for all academic levels and subjects?",
    content: [
      // Add dissertations service related content here
      "Yes, our dissertation writing services are available for all academic levels and subjects. ",
    ],
  },
  {
    label: "dissertations",
    id: 68,
    title: "How much does your dissertation writing service cost?",
    content: [
      // Add dissertations service related content here
      "Our dissertation writing service is priced on a custom basis, as the cost may vary depending on factors such as the academic level, subject area, complexity of the topic, deadline, and specific requirements of the project. However, as a rough estimate, our pricing typically starts at around $11 USD per page. Keep in mind that this is just a general guideline, and the final cost may be adjusted based on your unique needs. We aim to provide competitive pricing while ensuring high-quality work that meets your expectations. Feel free to contact us with your project details for a personalized quote.",
    ],
  },
  {
    label: "dissertations",
    id: 140,
    title: "Writing dissertation in 2 weeks?",
    content: [
      // Add dissertations service related content here
      "Our Ghostwriters can complete your dissertation within 5 business days.",
    ],
  },
  {
    label: "dissertations",
    id: 141,
    title: "Writing conclusion for dissertation?",
    content: [
      // Add dissertations service related content here
      "Its hard to write a conclusion for dissertation. Tell our write your journey and he'll get you the perfect conclusion to end your dissertation",
    ],
  },
  // app-development FAQ
  {
    label: "app-development",
    id: 69,
    title: "What is app development services?",
    content: [
      // Add app-development service related content here
      "App development services involve the creation of mobile applications for various platforms, including iOS, Android, and web-based applications.",
    ],
  },
  {
    label: "app-development",
    id: 70,
    title: "Can you provide examples of apps you have developed in the past?",
    content: [
      // Add app-development service related content here
      "Yes, we have developed a variety of apps in the past, including library management apps and travel booking apps. These apps are designed to streamline processes and enhance user experiences in their respective domains.",
    ],
  },
  {
    label: "app-development",
    id: 71,
    title: "What is the cost structure for app development services?",
    content: [
      // Add app-development service related content here
      "The cost structure for app development services varies depending on factors such as the complexity of the app, its features, platform compatibility, development time, and the development team's hourly rates. Generally, app development costs can range from 500 USD to several hundred thousand dollars, with more complex apps typically requiring higher investments.",
    ],
  },
  {
    label: "app-development",
    id: 72,
    title: "How do you handle app testing and quality assurance?",
    content: [
      // Add app-development service related content here
      "We employ thorough testing procedures and quality assurance measures, including functional, performance, security, usability, and compatibility testing.",
    ],
  },
  {
    label: "app-development",
    id: 73,
    title: "What kind of apps do you specialize in?",
    content: [
      // Add app-development service related content here
      "We specialize in developing a wide range of mobile applications, including but not limited to social networking apps, e-commerce apps, educational apps, gaming apps, productivity apps, and more.",
    ],
  },
  {
    label: "app-development",
    id: 74,
    title: "Do I own the source code of the app after development?",
    content: [
      // Add app-development service related content here
      "Yes, upon completion of the development process and full payment, you will own the source code of the app.",
    ],
  },
  {
    label: "app-development",
    id: 75,
    title: "Do you offer ongoing maintenance and support for apps?",
    content: [
      // Add app-development service related content here
      "Certainly, we typically do not include ongoing maintenance and support in our standard package, but if you require such services, it can be discussed and agreed upon beforehand. Any additional costs associated with maintenance and support will be included in the custom pricing based on your specific needs and requirements.",
    ],
  },
  {
    label: "app-development",
    id: 76,
    title: "Do you develop apps for both iOS and Android?",
    content: [
      // Add app-development service related content here
      "Yes, we develop apps for both iOS and Android platforms.",
    ],
  },
  {
    label: "app-development",
    id: 77,
    title: "What programming languages and frameworks do you use?",
    content: [
      // Add app-development service related content here
      "We utilize a diverse range of programming languages and frameworks tailored to project needs. Our expertise spans Swift, Objective-C, Java, Kotlin, React Native, Flutter, Node.js, Python, Ruby on Rails, PHP, and more, ensuring optimal development for iOS, Android, and cross-platform applications.",
    ],
  },
  {
    label: "app-development",
    id: 78,
    title: "Can you integrate my app with existing APIs?",
    content: [
      // Add app-development service related content here
      "Yes, we can integrate your app with existing APIs to enhance functionality and connectivity with external services or systems.",
    ],
  },
  {
    label: "app-development",
    id: 79,
    title: "What makes your app development services different?",
    content: [
      // Add app-development service related content here
      "Our app development services are distinguished by our commitment to thorough pre-project discussions, competitive pricing, timely delivery, and the use of human-made, unique code.",
    ],
  },
  // web-development
  {
    label: "web-development",
    id: 80,
    title: "What is basic web development?",
    content: [
      // Add web-development service related content here
      "Basic web development involves creating websites using HTML for structure, CSS for styling, and JavaScript for interactivity. It encompasses building web pages, designing layouts, and implementing functionality using various programming languages and frameworks.",
    ],
  },
  {
    label: "web-development",
    id: 81,
    title: "What services do you offer in web development?",
    content: [
      // Add web-development service related content here
      "In web development, we offer a wide range of services tailored to meet the specific needs of our clients. Whether it's building a website from scratch, redesigning an existing site, or implementing new features and functionalities, we provide comprehensive solutions to ensure our clients' online success.",
    ],
  },
  {
    label: "web-development",
    id: 82,
    title: "How long does it take to complete a web development project?",
    content: [
      // Add web-development service related content here
      "The time required to complete a web development project can vary significantly depending on the scope and complexity of the project. Simple websites with basic functionalities may be completed in a matter of weeks, while more complex projects with custom features and extensive requirements may take several months to finish. Additionally, factors such as client feedback and revisions can also impact the overall timeline.",
    ],
  },
  {
    label: "web-development",
    id: 83,
    title: "What technologies do you use for web development?",
    content: [
      // Add web-development service related content here
      "We tailor our web development services to meet the specific needs of each client, utilizing technologies such as HTML, CSS, JavaScript, React, Node.js, and various databases. Our goal is to deliver customized and scalable solutions that align with our clients' objectives and requirements.",
    ],
  },
  {
    label: "web-development",
    id: 84,
    title: "Can you redesign an existing website?",
    content: [
      // Add web-development service related content here
      "Yes, we offer website redesign services to improve the functionality, user experience, and overall aesthetics of existing websites. Whether you need minor adjustments or a complete overhaul, we can work with you to revamp your website and enhance its effectiveness.",
    ],
  },
  {
    label: "web-development",
    id: 85,
    title: "How much does web development cost?",
    content: [
      // Add web-development service related content here
      "The cost of web development varies depending on the specific requirements of the project, such as the complexity of the design, features, functionality, and the amount of customization needed. We provide custom quotes tailored to each client's unique needs and budget.",
    ],
  },
  {
    label: "web-development",
    id: 86,
    title: "Why choose us for web development services?",
    content: [
      // Add web-development service related content here
      "We deliver web development projects on time, leveraging skilled technology and expertise. Our customized solutions ensure high-quality websites that meet client specifications and exceed expectations.",
    ],
  },
  // graphic-designing FAQ
  {
    label: "graphic-designing",
    id: 87,
    title: "What types of graphic design services do you offer?",
    content: [
      // Add graphic-designing service related content here
      "We offer comprehensive graphic design services, covering logo design, branding, packaging, brochures, flyers, social media graphics, website graphics, and more. Our expertise ensures that we tailor our services to meet each client's specific needs, delivering high-quality designs that effectively communicate their message.",
    ],
  },
  {
    label: "graphic-designing",
    id: 88,
    title: "How much does graphic designing services cost?",
    content: [
      // Add graphic-designing service related content here
      "The cost of graphic design services varies depending on the scope and complexity of the project. We offer custom pricing based on factors such as the type of design, the number of revisions required, and any additional services needed. Contact us to discuss your project requirements and receive a personalized quote tailored to your needs.",
    ],
  },
  {
    label: "graphic-designing",
    id: 89,
    title: "How do I communicate my design preferences and ideas?",
    content: [
      // Add graphic-designing service related content here
      "You can communicate your design preferences and ideas through various channels such as email, video calls (e.g., Google Meet), recorded audio or video messages, or chat platforms. This allows for clear and effective communication, ensuring that your vision for the design is accurately understood and implemented by our team.",
    ],
  },
  {
    label: "graphic-designing",
    id: 90,
    title:
      "Is there a limit to the number of concepts or revisions I can request?",
    content: [
      // Add graphic-designing service related content here
      "We prioritize clear communication to accommodate your design preferences effectively. While there's no strict limit to revisions, providing upfront requirements minimizes back-and-forth. Small changes are generally included, but major revisions may incur additional costs.",
    ],
  },
  {
    label: "graphic-designing",
    id: 91,
    title: "Why choose us for graphic designing services?",
    content: [
      // Add graphic-designing service related content here
      "Choose us for graphic design services because we offer customized solutions tailored to your specific needs and preferences. With a focus on creativity, quality, and timely delivery, we ensure your vision is brought to life effectively.",
    ],
  },
  // programming FAQ
  {
    label: "programming",
    id: 92,
    title: "What programming languages do you specialize in?",
    content: [
      // Add programming service related content here
      "We specialize in programming languages such as Python, JavaScript, Java, C++, and more to cater to diverse client needs.",
    ],
  },
  {
    label: "programming",
    id: 93,
    title: "What types of programming services do you offer?",
    content: [
      // Add programming service related content here
      "We offer programming services for students' homework, assignments, and MS projects, as well as for professionals needing assistance with their tasks, all while ensuring unique and high-quality code.",
    ],
  },
  {
    label: "programming",
    id: 94,
    title: "What industries have you served in the past?",
    content: [
      // Add programming service related content here
      "We have served various industries, including companies like TCS and Wipro, as well as students from the USA, UK, and other regions.",
    ],
  },
  {
    label: "programming",
    id: 95,
    title: "Who owns the intellectual property rights to the finished product?",
    content: [
      // Add programming service related content here
      "Yes, the client owns the intellectual property rights to the finished product.",
    ],
  },
  {
    label: "programming",
    id: 96,
    title: "Why choose us for programming services?",
    content: [
      // Add programming service related content here
      "Our team of experienced professionals ensures timely delivery, competitive pricing, and unparalleled customer satisfaction.",
    ],
  },
  {
    label: "programming",
    id: 142,
    title: "Coding homework help free?",
    content: [
      // Add programming service related content here
      "WIth Assignment services, you get coding homework guidance free.",
    ],
  },
  {
    label: "programming",
    id: 143,
    title: "Free programming homework help?",
    content: [
      // Add programming service related content here
      "Yes, our assignment helper does help you with your programming homework, when you need to complete the assignment",
    ],
  },

  // game-development
  {
    label: "game-development",
    id: 97,
    title: "What services do you offer in game development?",
    content: [
      // Add game-development service related content here
      "We offer comprehensive game development services, including concept creation, game design, programming, artwork, sound design, testing, and deployment across various platforms. Our team can handle all aspects of game development, from mobile games to PC and console games, ensuring high-quality results that meet your specific requirements.",
    ],
  },
  {
    label: "game-development",
    id: 98,
    title: "What types of games do you specialize in developing?",
    content: [
      // Add game-development service related content here
      "We specialize in developing a wide range of games, including simple gamings commonly required for MS courses, as well as more complex genres like casual, puzzle, adventure, simulation, action, and role-playing games. Our expertise enables us to create engaging and immersive gaming experiences tailored to the specific needs and preferences of our clients.",
    ],
  },
  {
    label: "game-development",
    id: 99,
    title: "Can you help with both 2D and 3D game development?",
    content: [
      // Add game-development service related content here
      "Yes, we provide assistance in both 2D and 3D game development, offering diverse gaming experiences tailored to clients' needs.",
    ],
  },
  {
    label: "game-development",
    id: 100,
    title: "What platforms do you develop games for?",
    content: [
      // Add game-development service related content here
      "We develop games for various platforms, including mobile (iOS and Android), web, PC, and console.",
    ],
  },
  {
    label: "game-development",
    id: 101,
    title: "What is the cost of game development?",
    content: [
      // Add game-development service related content here
      "The cost of game development varies based on factors such as complexity, platform, and specific requirements. We offer custom pricing tailored to each project's needs and budget.",
    ],
  },
  {
    label: "game-development",
    id: 102,
    title: "What is your typical game development process?",
    content: [
      // Add game-development service related content here
      "Our typical game development process involves several stages, including concept development, design, prototyping, development, testing, and deployment. We work closely with clients to understand their vision and goals, ensuring that the final product meets their expectations.",
    ],
  },
  {
    label: "game-development",
    id: 103,
    title: "Why choose us for game development services?",
    content: [
      // Add game-development service related content here
      "Choose us for game development services because we have a team of experienced developers who are passionate about creating engaging and immersive gaming experiences.",
    ],
  },
  // exam-help service FAQ
  {
    label: "exam-help",
    id: 104,
    title: "Can I find help for my online exam?",
    content: [
      // Add exams service related content here
      "Yes, we offer assistance with online exams.",
    ],
  },
  {
    label: "exam-help",
    id: 105,
    title: "How can a website help me on my online exams? ",
    content: [
      // Add exams service related content here
      "You can find help for your online exam by booking tutoring sessions in advance through our website. Simply connect with us on WhatsApp to schedule a tutoring session for a specific time slot that suits your schedule.",
    ],
  },
  {
    label: "exam-help",
    id: 106,
    title: "Is it possible to hire someone to take my online exam?",
    content: [
      // Add exams service related content here
      "Yes, it is possible to hire someone to take your online exam. If this is something you need assistance with, please contact us on WhatsApp, and we can discuss the details further. We strive to accommodate our clients' needs to the best of our ability, provided it falls within our scope of services.",
    ],
  },
  {
    label: "exam-help",
    id: 107,
    title: "How does your online exam help service work?",
    content: [
      // Add exams service related content here
      "Our online exam help service works by allowing you to book a slot with us for the needed point of time through WhatsApp. Once you've contacted us and scheduled a slot, we will provide you with the necessary assistance during your online exam.",
    ],
  },
  {
    label: "exam-help",
    id: 108,
    title: "What subjects and exams do you cover?",
    content: [
      // Add exams service related content here
      "We cover a wide range of subjects and exams. Simply let us know your specific requirements on WhatsApp, and we'll do our best to accommodate your needs.",
    ],
  },
  {
    label: "exam-help",
    id: 109,
    title: "Is the online exam help service secure and confidential?",
    content: [
      // Add exams service related content here
      "Yes, our online exam help service is secure and confidential.",
    ],
  },
  {
    label: "exam-help",
    id: 110,
    title: "Is the online exam help service available 24/7?",
    content: [
      // Add exams service related content here
      "Yes, our online exam help service is available 24/7.",
    ],
  },
  {
    label: "exam-help",
    id: 111,
    title: "Why choose us for online exam help?",
    content: [
      // Add exams service related content here
      "You should choose us for online exam help because we have been providing this service for the past five years with a team of experts. Our experts are proficient in using tools like AnyDesk to meet the needs of our clients efficiently.",
    ],
  },
  // quizzes-help FAQ
  {
    label: "quizzes-help",
    id: 112,
    title: "How does the Online Quiz Help works?",
    content: [
      // Add quizzes-help service related content here
    ],
  },
  {
    label: "quizzes-help",
    id: 113,
    title: "Is it legal to use Online Quiz Help services?",
    content: [
      // Add quizzes-help service related content here
    ],
  },
  {
    label: "quizzes-help",
    id: 114,
    title: "hat subjects and topics do you cover for online quizzes-help?",
    content: [
      // Add quizzes-help service related content here
    ],
  },
  {
    label: "quizzes-help",
    id: 115,
    title: "Is my information kept confidential?",
    content: [
      // Add quizzes-help service related content here
    ],
  },
  {
    label: "quizzes-help",
    id: 116,
    title:
      "How can I trust the expertise of the professionals providing assistance?",
    content: [
      // Add quizzes-help service related content here
    ],
  },
  {
    label: "quizzes-help",
    id: 117,
    title: "What types of online quizzes-help do you support?",
    content: [
      // Add quizzes-help service related content here
    ],
  },
  {
    label: "quizzes-help",
    id: 118,
    title: "Do you offer help for time-sensitive quizzes-help or exams?",
    content: [
      // Add quizzes-help service related content here
    ],
  },
  {
    label: "quizzes-help",
    id: 119,
    title: "Why choose us for online quizzes-help help?",
    content: [
      // Add quizzes-help service related content here
    ],
  },
  // video-solutions FAQ
  {
    label: "video-solutions",
    id: 120,
    title: "What services do you offer in video solutions?",
    content: [
      // Add video-solutions service related content here
    ],
  },
  {
    label: "video-solutions",
    id: 121,
    title: "Do you offer video editing services for pre-recorded footage?",
    content: [
      // Add video-solutions service related content here
    ],
  },
  {
    label: "video-solutions",
    id: 122,
    title: "Can you help with video optimization for social media platforms?",
    content: [
      // Add video-solutions service related content here
    ],
  },
  {
    label: "video-solutions",
    id: 123,
    title: "What sets your video solution services apart from others?",
    content: [
      // Add video-solutions service related content here
    ],
  },
  {
    label: "video-solutions",
    id: 124,
    title: "What is the cost structure for your video services?",
    content: [
      // Add video-solutions service related content here
    ],
  },
  {
    label: "video-solutions",
    id: 125,
    title: "Why choose us for video solutions?",
    content: [
      // Add video-solutions service related content here
    ],
  },
  // live-session FAQ
  {
    label: "live-session",
    id: 126,
    title: "How do I book a live session?",
    content: [
      // Add live-session service related content here
    ],
  },
  {
    label: "live-session",
    id: 127,
    title: "What advantages can I gain by participating in live sessions?",
    content: [
      // Add live-session service related content here
    ],
  },
  {
    label: "live-session",
    id: 128,
    title: "What services are you offering via live sessions?",
    content: [
      // Add live-session service related content here
    ],
  },
  {
    label: "live-session",
    id: 129,
    title: "What topics are covered in live sessions?",
    content: [
      // Add live-session service related content here
    ],
  },
  {
    label: "live-session",
    id: 130,
    title: "Are the sessions one-on-one or group sessions?",
    content: [
      // Add live-session service related content here
    ],
  },
  {
    label: "live-session",
    id: 131,
    title: "Can I access recordings of the sessions later?",
    content: [
      // Add live-session service related content here
    ],
  },
  // lab-report FAQ
  {
    label: "lab-report-help",
    id: 132,
    title: "What type of lab reports do you provide?",
    content: [
      // Add lab-report service related content here
      "Certainly! In addition to physics, chemistry, and all branches of engineering, we also provide laboratory reports for mathematical experiments and analyses. Our team is equipped to handle a wide range of subjects and disciplines to meet your academic or research needs.",
    ],
  },
  {
    label: "lab-report-help",
    id: 133,
    title: "Who are the writers responsible for crafting the lab reports?",
    content: [
      // Add lab-report service related content here
      "Our lab reports are crafted by top-class experts specialized in physics, chemistry, engineering, mathematics, and other relevant fields, ensuring high-quality, tailored content that meets university standards.",
    ],
  },
  {
    label: "lab-report-help",
    id: 134,
    title: "How can I track the progress of my lab report writing?",
    content: [
      // Add lab-report service related content here
      "You can track the progress of your lab report writing by contacting us anytime, as we are available 24/7 to provide updates and address any queries you may have. Simply reach out to us via WhatsApp, and we'll be happy to assist you.",
    ],
  },
  {
    label: "lab-report-help",
    id: 135,
    title: "What is the cost structure of lab report writing?",
    content: [
      // Add lab-report service related content here
      "We offer customized pricing for lab report writing based on project requirements, ensuring fair and accurate cost estimation.",
    ],
  },
  {
    label: "lab-report-help",
    id: 136,
    title: "Do you guarantee plagiarism-free work?",
    content: [
      // Add lab-report service related content here
      "Yes, we guarantee plagiarism-free lab reports and provide a complimentary Turnitin report free of cost to ensure originality.",
    ],
  },
  {
    label: "lab-report-help",
    id: 137,
    title:
      "Can you help me with the introduction or conclusion of my lab report?",
    content: [
      // Add lab-report service related content here
      "Yes, we can assist you with crafting the introduction or conclusion of your lab report.",
    ],
  },
  {
    label: "lab-report-help",
    id: 138,
    title: "Do you offer rush services for urgent deadlines?",
    content: [
      // Add lab-report service related content here
      "Yes, we do offer rush services for urgent deadlines. However, we need to review all your requirements beforehand to ensure feasibility within the given timeframe.",
    ],
  },
  {
    label: "lab-report-help",
    id: 139,
    title: "Why choose us for lab report writing?",
    content: [
      // Add lab-report service related content here
      "Choose us for lab report writing because we have experts from various fields who deliver timely work with plagiarism-free content, ensuring you receive the best grades.",
    ],
  },
];
