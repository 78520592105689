import React from 'react';
import { useParams } from 'react-router-dom';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import ProjectPropOne from '../component/project/itemProp/ProjectPropOne';
import { slugify } from '../utils';
import ServiceData from "../data/service/ServiceMain.json";
import ProjectData from "../data/project/ProjectData.json";
import ProcessOne from '../component/process/ProcessOne';
import AboutTwo from '../component/about/AboutTwo';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';
import { services } from '../data/seo/services';
import FormOne from '../component/contact/FormOne';
import "./style.css"

const allServiceData = ServiceData;
const getProjectData = ProjectData;

const ServiceDetails = () => {

    const params = useParams(); // Move the useParams hook inside the component

    const slug = params.slug;
    const serviceName = slug;
    

    const formatRouteParam = (slug) => {
        console.log("format inside",slug)
        return serviceName.replace(/-/g, " ").replace(/%20/g, " ");
    };
    console.log("Service Name", slug)

    const getServiceData = services.filter(data => slugify(data.name) === slug);
    const detailsService = getServiceData[0];
    const currentServiceOption = getServiceData[0];
    const aboutImage = currentServiceOption && currentServiceOption.aboutImage || "";
    const bannerTitle = currentServiceOption && currentServiceOption.bannerTitle || formatRouteParam(params.serviceName);
    const bannerDesc = currentServiceOption && currentServiceOption.bannerDesc || "Revolutionize your career with online homework \n help available today!";
    const whyTitle = currentServiceOption && currentServiceOption.whyTitle || "Why you need this particular section";

    const aboutTitle = currentServiceOption && currentServiceOption.aboutTitle || (
        <>
            Tutorpoint{" "}
            <span className="capitalize">{formatRouteParam(params.serviceName)}</span> - One
            place for best quality{" "}
            <span className="capitalize">{formatRouteParam(params.serviceName)}</span>
        </>
    );
    const howWorksTitle = currentServiceOption && currentServiceOption.howWorksTitle || (
        <>How {formatRouteParam(params.serviceName)} works</>
    );


    return (
        <>
            <SEO title="Service Details" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BcrumbBannerOne
                    title={detailsService.altName ? detailsService.altName : detailsService.name}
                    paragraph={detailsService.seoDescription}
                    styleClass=""
                    mainThumb="/images/banner/banner-thumb-4.png"
                />
                <div className="service-about-section mx-auto py-6 md:py-16 pt-10 max-w-screen-xl px-6">
                    <div className="about-content-container">
                        <div className="content-part">
                            <h4 className="text-2xl font-semibold text-black">
                                {aboutTitle}
                            </h4>   
                            {/* Inside the service-about-section mapping */}
                            {services
                                .filter(
                                    (service) => service.label === formatRouteParam(serviceName)
                                )
                                .map((service, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            {service.serviceAboutSectionPara.map(
                                                (para, paraIndex) => (
                                                    <p
                                                        key={paraIndex}
                                                        className="text-justify  leading-7 tracking-wide"
                                                    >
                                                        {para}
                                                    </p>
                                                )
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                        </div>
                        <div className="image-part">
                            <img
                                src={aboutImage}
                                alt="assignment"
                                title="homework"
                                width="100%"
                                height="100%"
                            />
                        </div>
                    </div>
                </div>

                <div className="service-why-section mx-auto py-6 md:py-12 max-w-screen-xl px-6">
                    <div className="why-content-container">
                        <div className="image-part">
                            <img
                                src={'https://lh3.googleusercontent.com/drive-viewer/AKGpihbr2oz0XjuzJPFth7jh3LtCnxg58aghxP9PZ6LvdffYbwaGbJQYxB6TH1QbzlmFPx2T6oi0zK0aDOVQ1FMPBtXJdNQPDNdHT0A=s1600-v0'}
                                alt="homework"
                                title="assignment"
                                width="100%"
                                height="100%"
                            />
                        </div>
                        <div className="content-part">
                            <h4 className="text-2xl font-semibold text-black">
                                {whyTitle}
                            </h4>
                            {/* Inside the service-why-section mapping */}
                            {services
                                .filter(
                                    (service) => service.label === formatRouteParam(serviceName)
                                )
                                .map((service, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            {service.serviceWhySectionPara.map(
                                                (para, paraIndex) => (
                                                    <p
                                                        key={paraIndex}
                                                        className="text-justify leading-7 tracking-wide"
                                                    >
                                                        {para}
                                                    </p>
                                                )
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                        </div>
                    </div>
                </div>



                {/* <AboutTwo /> */}

                {/* <ProcessOne /> */}
                <div className="section section-padding">
                    <div className="container">
                        <SectionTitle
                            subtitle="Our Project"
                            title="Featured Designs"
                            description=""
                            textAlignment=""
                            textColor=""
                        />
                        <div className="row row-35">
                            {getProjectData.slice(0, 2).map((data) => (
                                <div className="col-md-6" key={data.id}>
                                    <ProjectPropOne projectStyle="" portfolio={data} />
                                </div>
                            ))}
                        </div>
                    </div>
                    <ul className="shape-group-16 list-unstyled">
                        <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-2.png"} alt="circle" /></li>
                        <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="Line" /></li>
                    </ul>
                </div>
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default ServiceDetails;