import React, {useRef, useState} from 'react';
import emailjs from '@emailjs/browser';
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';

const Result = () => {
    return (
        <Alert variant="success" className="success-msg">
        Your Message has been successfully sent.
        </Alert>
    )
}

const FormTwo = () => {

    const form = useRef();
    const [data,setData] = useState({
        device_number:"Device 4",
        name:"",
        email:"",
        phone:"",
        contactMessage:""
    })

    const [ result, showresult ] = useState(false);


    const handleChange = (e) =>{
        setData({
            ...data,
            [e.target.name] : e.target.value
        })
    }

    const sendEmail = async(e) => {
        e.preventDefault();
        const sendData = await axios.post("https://dev6apis.el.r.appspot.com/api/deviceWeb/saveDeviceWebData",data)
        // emailjs.sendForm('service_yj5dgzp', 'template_hfduayo', form.current, 'WLENsTkBytC0yvItS')
        //   .then((result) => {
        //       console.log(result.text);
        //   }, (error) => {
        //       console.log(error.text);
        //   });
        if(sendData.data && sendData.data.success){
          form.current.reset();
          showresult(true);
        }
      };

        setTimeout(() => {
            showresult(false);
        }, 5000);


    return (
        <form ref={form} onSubmit={sendEmail} className="axil-contact-form">
        <div className="form-group">
            <label>Name</label>
            <input type="text" className="form-control" name="name" onChange={handleChange}  required />
        </div>
        <div className="form-group">
            <label>Email</label>
            <input type="email" className="form-control" name="email" onChange={handleChange} required />
        </div>
        <div className="form-group">
            <label>Phone</label>
            <input type="tel" className="form-control" name="phone" onChange={handleChange} required />
        </div>
        <div className="form-group mb--40">
            <label>How can we help you?</label>
            <textarea className="form-control" name="contactMessage" onChange={handleChange} rows="4"></textarea>
            
        </div>
        <div className="form-group">
            <button type="submit" className="axil-btn btn-fill-primary btn-fluid btn-primary" name="submit-btn">Submit</button>
        </div>
        <div className="form-group">
            {result ? <Result /> : null}
        </div>

    </form>
    )
}

export default FormTwo;