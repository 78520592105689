import React from 'react';
import FormOne from '../contact/FormOne';


const AboutOne = () => {
    return (
      <section
        id="aboutus"
        className="section section-padding-equal bg-color-light"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-us">
                <div className="section-heading heading-left mb-0">
                  <span className="subtitle">About Us</span>
                  <h2 className="title mb--40">
                    We help, guide &amp; develop.
                  </h2>
                  <p>
                    We are a team of expert academicians who can help you bring
                    good grades in all your subjects. We provide tutoring, and
                    doubt clearing 24X7. Get help with all your assignments and
                    assessments. We organize Live Question Sessions and
                    Assignment Sessions.{" "}
                  </p>
                  <p>Your academic issues are resolved here.. </p>
                </div>
              </div>
            </div>

            <div className="col-xl-5 col-lg-6 offset-xl-1">
              <div className="contact-form-box">
                <h3 className="title">Fill Details</h3>
                <FormOne />
              </div>
            </div>
          </div>
        </div>
        <ul className="shape-group-6 list-unstyled">
          <li className="shape shape-1">
            <img
              src={process.env.PUBLIC_URL + "/images/others/bubble-7.png"}
              alt="Bubble"
            />
          </li>
          <li className="shape shape-2">
            <img
              src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
              alt="line"
            />
          </li>
          <li className="shape shape-3">
            <img
              src={process.env.PUBLIC_URL + "/images/others/line-5.png"}
              alt="line"
            />
          </li>
        </ul>
      </section>
    );
}

export default AboutOne;