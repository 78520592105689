import React from "react";
import { Link } from "react-router-dom";
import { FaFacebookF, FaDribbble, FaLinkedinIn } from "react-icons/fa";
import { BsWhatsapp, BsFacebook } from "react-icons/bs";
import "./footer.css";

const SplashFooter = () => {
  return (
    <footer className="footer-area splash-footer">
      <div className="container">
        <div className="footer-bottom">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="footer-copyright">
                <span className="copyright-text">
                  © {new Date().getFullYear()}. All rights reserved by ABHAY
                  EDUCATION PVT. LTD.
                </span>
                {/* <span className="copyright-text" style={{fontSize:"14px"}}> A Maharashtra registered Pvt. Ltd. Company <br/> CIN : U74999MH2012PTC232048</span> */}
              </div>
            </div>
            <div className="col-lg-2">
              <ul className="footer-social list-unstyled">
                {/* <li><a href="https://www.facebook.com/"><FaFacebookF /></a></li> */}
                {/* <li><a href="https://dribbble.com/axilweb/"><FaDribbble /></a></li> */}
                {/* <li><a href="https://www.linkedin.com/"><FaLinkedinIn /></a></li> */}
              </ul>
            </div>
            <div className="col-lg-5">
              <div className="footer-bottom-link">
                {/* <ul style={{paddingBottom:'5px',paddingRight:'3px'}} className="list-unstyled">
                                <li><a href="https://themeforest.net/user/axilthemes/portfolio">More Themes</a></li>
                                <li><Link to={process.env.PUBLIC_URL + "/privacy-policy"}>Privacy Policy</Link></li>
                                <li><a href="https://www.facebook.com/"><FaFacebookF /></a></li>
                                <li><a href="https://www.linkedin.com/"><FaLinkedinIn /></a></li>
                            </ul> */}
                <div className="footer-menu-link">
                  <ul className="list-unstyled">
                    <li>
                      <Link
                        to="/refund"
                        // {process.env.PUBLIC_URL + "/contact"}
                      >
                        Refund
                      </Link>
                    </li>
                    <li>
                      {" "}
                      <Link
                        to="/privatepolocies"
                        // {process.env.PUBLIC_URL + "/privacy-policy"}
                      >
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/termsandcondition"
                        // {process.env.PUBLIC_URL + "/terms-use"}
                      >
                        Terms & Conditions
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="social">
        <a
          href="https://www.facebook.com/profile.php?id=100091312965603"
          target="_blank"
          rel="noreferrer"
        >
          <div class="social-btn color-telegram">
            <BsFacebook class="icons8-telegram-app" />
            <p class="order-1 google-font margin-telegram">Facebook</p>
          </div>
        </a>
        <a
          href="https://www.instagram.com/the_assignmentsolver/"
          target="_blank"
          rel="noreferrer"
        >
          <div class="social-btn color-instagram">
            <div class="icons8-instagram"></div>
            <p class="order-1 google-font margin-instagram">Instagram</p>
          </div>
        </a>
        <a href="https://wa.me/918777592248" target="_blank" rel="noreferrer">
          <div class="social-btn color-whatsapp">
            <BsWhatsapp class="icons8-whatsapp" />
            <p class="order-1 google-font margin-instagram">Whatsapp</p>
          </div>
        </a>
      </div>
    </footer>
  );
};

export default SplashFooter;
