import React from 'react';
import Tilty from 'react-tilty';
import FormOne from '../../component/contact/FormOne';

const BcrumbBannerOne = ({ title, paragraph, styleClass, mainThumb }) => {
    return (
        <div className="breadcrum-area breadcrumb-banner">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-7">
                        <div className="section-heading heading-left">
                            <h1 className="title h2" dangerouslySetInnerHTML={{ __html: title }}></h1>
                            <p>{paragraph}</p>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="contact-form-box">
                            <h3 className="title">Fill Details</h3>
                            <FormOne />
                        </div>
                    </div>
                </div>
            </div>

            <ul className="shape-group-8 list-unstyled">
                <li className="shape shape-1">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-9.png"} alt="Bubble" />
                </li>
                <li className="shape shape-2">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-21.png"} alt="Bubble" />
                </li>
                <li className="shape shape-3">
                    <img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="Line" />
                </li>
            </ul>
        </div>
    )
}

export default BcrumbBannerOne;
