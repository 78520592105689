 
import React from "react"
import { Link } from "react-router-dom"
const PrivatePolocies = () => {
  return (
    <div style={{ padding:'30px',backgroundColor:"whitesmoke"}}>
    <div style={{maxWidth:'1200px',margin:'auto'}}>
      <li ><Link className="axil-btn btn-fill-primary btn-fluid btn-primary" style={{color:'white', fontSize:'20px'}} to={process.env.PUBLIC_URL + "/"}>Go to Home</Link></li>
    <h1 style={{color:'darkblue'}}>Overview</h1>
    <p style={{color:'black'}}>We understand your concern about privacy and security of personal details. It is our ethical duty to make you aware of our privacy policy. Here is what we do with your personal information:</p>
    <p style={{color:'black'}}>- All information about students and tutors is strictly kept within the company.</p>
    <p style={{color:'black'}}>- We do not give out any student or {"tutor's"} information to third parties.</p>

    <h1 style={{color:'darkblue'}}>Collection of Information</h1>
    <p style={{color:'black'}}>Payments are handled by PayPal, Razorpay. We have collaborated with PayPal and razorpay so that they alone (and not our company) can deal with a {"student's"} credit card details.</p>
    <p style={{color:'black'}}>We reserve the right to disclose your personal identifiable information as required by law and when we believe that disclosure is necessary to protect our rights and/or to comply with a judicial proceeding, court order or legal process served on our Website.</p>

    <h1 style={{color:'darkblue'}}>Use and sharing of Information</h1>
    <p style={{color:'black'}}>assignmentguru.com reserves the right to modify this privacy statement any time, so please review it frequently. Any changes made to above policy will be posted here and your continued use of the site, services and/or software constitutes your agreement to be bound by such changes.</p>
    <p style={{color:'black'}}>We value your privacy and would like to inform you that you would be receiving our emails after you signed up with us to avail our services. We treat your email ids with utmost care and do not share them with anyone else. So you can be rest assured that all emails sent from us are designed to benefit you solely.</p>

</div>
</div>
  )
}

export default PrivatePolocies