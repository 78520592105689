import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa";
import { BsWhatsapp } from "react-icons/bs";
import "./demo.css";
import { services } from "../../data/seo/services";
import { clarity } from "react-microsoft-clarity";

const Nav = (prop) => {

  const WhatsppButtonClicked=()=>{
    clarity.setTag(" Header-WhatsApp-Button-Clicked","yes")
  }
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setIsDropdownOpen(false);
  };
  return (
    <nav className="mainmenu-nav">
      <ul className="mainmenu">
        <li className="menu-item-has-children">
          <Link to="/">
            Home
            {/* <FaAngleDown />{" "} */}
          </Link>
          {/* <ul className="axil-submenu">
            <li>
              <Link to={process.env.PUBLIC_URL + "/digital-agency"}>
                Digital Agency
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/creative-agency"}>
                Creative Agency
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/personal-portfolio"}>
                Personal Portfolio
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/home-startup"}>
                Home Startup
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/corporate-agency"}>
                Corporate Agency
              </Link>
            </li>
          </ul> */}
        </li>
        <li className="menu-item-has-children">
          {/* <Link to="/#aboutus">
            About Us
           
          </Link> */}
          <a href="/#aboutus">
            About Us

          </a>

        </li>
        <li className="menu-item-has-children">
          <div className="dropdown submenu  nav-item" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <p style={prop.pageNo === 1 ? { } : {color: "#ecf2ec"}} className={
              "dropdown-toggle mt-4 mb-4 nav-link "} aria-haspopup="true" aria-expanded={isDropdownOpen ? 'true' : 'false'}>
              Services
            </p>
            <ul role="menu" className={`ultag dropdownnav-menu ${isDropdownOpen ? 'open' : ''}`}>
              {services.map((option, index) => (
                <li className="litag nav-service" key={index}>
                  <Link
                    exact
                    // onClick={() => closeServiceDropdown(`/services/${option.href}`)}
                    className="nav-service-link"
                    style={{color:"black"}}
                    onClick={handleMouseLeave}
                    to={`/service-details/${option.href}`}
                  >
                    {option.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </li>


        {/* <li className="menu-item-has-children">
          <Link to="#">
            Services 
          </Link>
          <ul className="axil-submenu">

            {
              services.map((service) => (
            <li key={service.name}>
              <Link to={process.env.PUBLIC_URL + `/service-details/${service.href}`}>
                {service.name}
              </Link>
            </li>

              ))
            }
           
          </ul>
        </li> */}
        {/* <li className="menu-item-has-children"> */}
        {/* <Link to="/">
            Pricing
           
          </Link> */}
        {/* <ul className="axil-submenu">
            <li>
              <Link to={process.env.PUBLIC_URL + "/digital-agency"}>
                Digital Agency
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/creative-agency"}>
                Creative Agency
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/personal-portfolio"}>
                Personal Portfolio
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/home-startup"}>
                Home Startup
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/corporate-agency"}>
                Corporate Agency
              </Link>
            </li>
          </ul> */}
        {/* </li> */}
        {/* <li className="menu-item-has-children"> */}
        {/* <Link to="#">Portfolio <FaAngleDown /></Link> */}
        {/* <ul className="axil-submenu">
                        <li><Link to={process.env.PUBLIC_URL + "/project-grid-one"}>Two Column</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/project-grid-two"}>Three Column</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/project-grid-three"}>Four Column</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/project-width-one"}>Three Column Width</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/project-width-two"}>Four Column Width</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/project-details/plan-management"}>Portfolio Details</Link></li>
                    </ul> */}
        {/* </li> */}
        {/* <li className="menu-item-has-children"> */}
        {/* <Link to="#">Pages <FaAngleDown /></Link> */}
        {/* <ul className="axil-submenu">
          <li>
            <Link to={process.env.PUBLIC_URL + "/about-us"}>About Us</Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + "/our-office"}>Our Office</Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + "/case-study"}>Case Study</Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + "/case-details/whitehorse"}>
              Case Study Details
            </Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + "/team"}>Team</Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + "/team-details/jane-cooper"}>
              Team Details
            </Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + "/our-clients"}>
              Our Clients
            </Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + "/testimonials"}>
              Testimonial
            </Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + "/pricing-table"}>
              Pricing Table
            </Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + "/typography"}>Typography</Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + "/404"}>404 Page</Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + "/coming-soon"}>
              Coming Soon
            </Link>
          </li>
        </ul> */}
        {/* </li> */}
        <li className="menu-item-has-children">
          <Link to="/blogs/">Blog </Link>
          {/* <ul className="axil-submenu">
            <li>
              <Link to={process.env.PUBLIC_URL + "/blog-grid"}>Blog</Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/blog-details/1"}>
                Standard Post
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/blog-details/2"}>
                Gallery Post
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/blog-details/3"}>
                Video Post
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "#"}>Audio Post</Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "#"}>Quote Post</Link>
            </li>
          </ul> */}
        </li>
        {/* <li><Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link></li> */}
        <li>
          <a
            href="https://wa.me/918777592248"
            target="_blank"
            onClick={WhatsppButtonClicked}
            //   className="flex gap-2 items-center rounded-full bg-green-dark md:px-8 md:py-4 px-4 py-2 text-sm font-medium text-white transition hover:bg-indigo-700 capitalize"
            className="whatsapp-linear"
          //   rel="noreferrer"
          >
            <BsWhatsapp className="md:text-xl md:font-bold text-lg font-normal" />
            Chat with Us
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
