"use client"
import React, { useEffect, memo } from 'react';
import gsap from 'gsap';
import logo from "./dummy/theAssignmentSolver.png";

import './style.css'
const Loading = memo(function Loading({ fade }) {
  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.from('.preloader', {
        width: '0%',
        duration: 1,
        repeat: -1,
        yoyo: true,
        ease: 'power1.inOut',
      });
    });
    return () => ctx.revert();
  }, []);

  useEffect(() => {
    console.log('fade effect', fade, Date.now());
    if (fade) {
      gsap.to('.loader-wrapper', { opacity: 0, ease: 'power1.in' });
    }
  }, [fade]);

  return (
    <div className="loader-container fixed flex flex-col justify-center bg-white items-center w-screen  -mt-[5%] h-[120%] z-[1000] loader-wrapper">
      
      {/* <Image
        src={logo}
        loading="eager"
        alt="Genius Tutors Logo"
        title="Genius Tutors Logo"
        className="image w-20 md:w-24"
      />
      <div className="preloader h-1 w-28 bg-black">&nbsp;</div>
      <h1 className=" font-bold text-black loader-text">
        Loading . . .
      </h1> */}
      <div class="loaderbar h-auto flex justify-center">
      <img
        src={logo}
        loading="eager"
        alt="Genius Tutors Logo"
        title="Genius Tutors Logo"
        className="image w-20 -mt-10 md:w-24"
      />
      
        <label className='waitWord'>Please wait...</label>
        <div class="loading"></div>
      </div>
      

    </div>
  );
});

export default Loading;
