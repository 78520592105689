/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import React from "react";
import {truncateString} from "../../utils/truncateString";

export function ShortBlogCard({
  src,
  metadescription,
  title,
  to,
  author,
  category,
  date,
}) {
  const para = truncateString(metadescription, 140);

  return (
    <div className="box shadow-sm hover:shadow-md ">
      <a href={to} className="cursor-pointer hover:no-underline">
        <div>
          <div color="blue-gray ">
            <img
              loading="eager"
              src={src}
              alt="ui/ux review check"
              title="ui/ux review check"
              className="w-full h-36 object-cover"
              width="100%"
              height="100%"
            />
          </div>
          <div className="px-3 py-2 w-full">
            <h2
              color="blue-gray"
              className="text-base font-bold font-QuickSand"
            >
              {title}
            </h2>

            {metadescription !== undefined && (
              <p className="pt-4 text-sm font-medium font-Nunito">{para}</p>
            )}

            <div className="flex justify-between w-full gap-2 pt-4">
              <p className="text-xs font-bold font-Nunito">
                Author:{" "}
                <span className="text-xs font-medium font-QuickSand">
                  {author}
                </span>
              </p>
              <p className="text-xs font-bold font-Nunito">
                Topic:{" "}
                <span className="text-xs font-medium font-QuickSand">
                  {category}
                </span>
              </p>
              <span className="text-xs font-bold font-Nunito">{date}</span>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
}
