import React from 'react';
import { Link } from 'react-router-dom';
import FormOne from '../contact/FormOne';


const BannerFive = () => {
    return (
        <div className="banner banner-style-5" style={{backgroundImage:`url(${process.env.PUBLIC_URL + "/images/banner/bannerTheAssignmentSolver.jpg"})`}}>
            <div className="container">
                <div className="row">
                    <div>

                    </div>
                    <div className="col-lg-16 col-xl-18">
                        <div className="banner-content">
                            <h2 className="title">All your acedemic problems <br/>end here
                                {/* &amp; Support  */}
                                </h2>
                            <div style={{marginBottom:"3rem"}}>
                                <a href={'https://wa.me/message/H7VVH7OB5LYTM1'} target='_blank' className="axil-btn btn-fill-white btn-large">Connect Now</a>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-lg-6 col-xl-5">
                        <div className="banner-form">
                            <div className="contact-form-box shadow-box">
                                <h3 className="title">Get a free Keystroke quote now</h3>
                                <FormOne />
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default BannerFive;